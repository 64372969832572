import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal/index';
import { ModalContainer, ModalWrapper } from '../common/Modal/Component';
import { ImageContainer, Image } from '../common/ui/Image';
import CutomButton from '../common/ui/CutomButton';
import {
    NewsCardWrapper,
    NewsCardContainer,
    OverlayContainer,
    ViewImageBody,
    ViewImageHeader,
    ViewImageTitle
} from './Component';
import Text from '../common/ui/Text';
import Tag from '../common/ui/Tag';
import CustomLink from '../common/ui/CustomLink'
import { downloadImage } from '../../utils/helpers';

const NewsCard = ({ imgSrc, title, width, tag, date, icon, link, openImageText, titleModal, downloadText }) => {
    const [ toggleModal, setToggleModal ] = useState(false);

    useEffect(() => {
        if (toggleModal) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [toggleModal]);

    const modalHandler = e => {
        e.stopPropagation();
        if(e.target.getAttribute("class").split(" ").includes("Back") === false && e.target.parentElement.getAttribute("class").split(" ").includes("Back") === false){
            setToggleModal(!toggleModal);
        }
    }

    return (
        <NewsCardWrapper>
            <NewsCardContainer
                align="left"
            >
                <ImageContainer
                    margin="0px 0px 5px"
                    sPadding="5px 10px"
                    sdisplay="block"
                    display="none"
                >
                    <Image
                        src={icon}
                        width={width}
                    />
                </ImageContainer>
                {imgSrc && <ImageContainer
                    sMargin="0px 0px 10px"
                    borderRadius="6px"
                    hoverBackgroundColor="BLACK"
                    backgroundColor="BACKGROUND_PLACEHOLDER"
                    display="flex"
                    flexDirection="column"
                    flex='1'
                    jContent="center"
                    opacityDiv="1"
                    opacityImage="0.3"
                    position="relative"
                    onClick={modalHandler}
                    cursor="pointer"
                >
                    <Image
                        src={imgSrc}
                    />
                    <OverlayContainer>
                        <Text
                            size="sm"
                            borderRadius="4px"
                            border="1px solid white"
                            color="WHITE"
                            padding="1px 8px"
                            cursor="pointer"
                            lotBorderRight="1px solid white"
                        >
                            {openImageText}
                            <Image
                                src={`https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603802738/chqbookWeb/MediaCenter/arrow_3x.png`}
                                width="10px"
                                style={{ margin:"0px 0px 2px 5px" }}
                            />
                        </Text>
                    </OverlayContainer>
                </ImageContainer>}
                {imgSrc && <Modal
                    show={toggleModal}
                    modalClosed={modalHandler}
                >
                    <ModalContainer
                        top="50%"
                        left="50%"
                        sBottom="0px"
                        sTop="unset"
                        sLeft="unset"
                        sTransform="unset"
                        padding="0px"
                        borderRadius="0px"
                        transform="translate(-50%,-50%)"
                        width="75%"
                        mWidth="95%"
                        sWidth="100%"
                        backgroundColor="#444"
                        className="Back"
                    >
                        <ModalWrapper
                            className="Back"
                            margin="0px"
                        >
                            <ViewImageHeader
                                className="Back"
                            >
                                <ViewImageTitle
                                    className="Back"
                                >
                                    {titleModal}
                                </ViewImageTitle>
                                <CutomButton
                                    borderradius="4px"
                                    padding="0px 10px"
                                    width="max-content"
                                    height="auto"
                                    border="1px solid white"
                                    sdisplay="none"
                                    size="sm"
                                    hoverbackground="transparent"
                                    backgroundcolor="transparent"
                                    className="Back"
                                    onClick={downloadImage.bind(null, imgSrc, titleModal)}
                                >
                                    {downloadText}
                                </CutomButton>
                                <ImageContainer
                                    onClick={modalHandler}
                                    width="12px"
                                    margin="3px 0px 3px 45px"
                                    cursor="pointer"
                                >
                                    <Image
                                        src={"https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603809443/chqbookWeb/MediaCenter/close-icon_3x.png"}
                                    />
                                </ImageContainer>
                            </ViewImageHeader>
                            <ViewImageBody
                                className="Back"
                            >
                                <ImageContainer
                                    borderRadius="0px"
                                    className="Back"
                                >
                                    <Image
                                        src={imgSrc}
                                        className="Back"
                                    />
                                </ImageContainer>
                                <CutomButton
                                    border="1px solid white"
                                    color="WHITE"
                                    padding="5px 10px"
                                    smargin="25px auto 30px"
                                    borderradius="4px"
                                    width="max-content"
                                    height="auto"
                                    display="none"
                                    sdisplay="block"
                                    backgroundcolor="transparent"
                                    size="md"
                                    hoverbackground="transparent"
                                    className="Back"
                                    onClick={downloadImage.bind(null, imgSrc, titleModal)}
                                >
                                    {downloadText}
                                </CutomButton>
                            </ViewImageBody>
                        </ModalWrapper>
                    </ModalContainer>
                </Modal>}
                <CustomLink target="_blank" to={`${link}`}>
                    <ImageContainer
                        margin="15px 0px 0px"
                        mMargin="10px 0px"
                        sdisplay="none"
                        minHeight= "75px"
                        display='flex'
                        imgSelfAlign='center'
                    >
                        <Image
                            src={icon}
                            width={width}
                        />
                    </ImageContainer>
                    {tag && <Tag
                        size="xs"
                        weight="large"
                        color="TAG"
                        lineHeight="normal"
                        width="max-content"
                        padding="7px 8px 5px 10px"
                        margin="0px 15px 0px 0px"
                        backgroundColor="rgb(232 46 47 / 0.05)"
                        display="inline-block"
                    >
                        {tag}
                    </Tag>}
                    <Text
                        size="xxs"
                        color="MEDIUM_BLACK"
                        weight="medium"
                        display="inline-block"
                    >
                        {date}
                    </Text>
                    <Text
                        size="xxl"
                        align="left"
                        weight="large"
                        lineHeight= "1.67"
                        letterSpacing= "-0.23px"
                        padding="5px 0px 0px"
                    >
                        {title}
                    </Text>
                </CustomLink>
            </NewsCardContainer>
        </NewsCardWrapper>
    )
}

export default NewsCard;
