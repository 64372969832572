import React from 'react';
import News from '../../components/News'
import { graphql } from 'gatsby';
import { modifyContent } from '../../utils/helpers';

export const query = graphql`
        query{
            content: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#media-center-news"}}},
                }
            ) {
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
            news: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#news"}}},
                }
            ) {
                nodes {
                    plaintext
                }
            }
        }
    `;

const NewsPage = props => {
    let content = modifyContent(props.data);
    const title = props.data.content?.nodes[0].meta_title;
    const description = props.data.content?.nodes[0].meta_description;

    return (
        <News
            data={content.content}
            newsList={content.news}
            title={title}
            description={description}
            location={props.location}
        />
    )
}

export default NewsPage;
